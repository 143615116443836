<template>
    <div class="rb-view">
      <div style="margin-bottom: 32px;">
        成品记录
      </div>
      <div>
        <el-form :ref="search_form_name" :model="search_option" label-position="right" size="default">
          <el-row>
            <el-col :span="12">
              <el-form-item label="日期:" prop="note_at_range">
                <el-radio-group v-model="search_option.note_at_range" @change="handleSearchTap">
                  <el-radio-button label="all">全部</el-radio-button>
                  <el-radio-button label="today">今日</el-radio-button>
                  <el-radio-button label="yesterday">昨天</el-radio-button>
                  <el-radio-button label="7day">7天内</el-radio-button>
                  <el-radio-button label="30day">30天内</el-radio-button>
                  <el-radio-button label="this_month">今月</el-radio-button>
                  <el-radio-button label="this_year">今年</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="自定义:" prop="note_at_range">
                <el-date-picker @change="handleSearchTap"
                                v-model="search_option.note_at_range"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="状态:" prop="status">
                <el-radio-group v-model="search_option.status" @change="handleSearchTap">
                  <el-radio-button label="all">全部</el-radio-button>
                  <el-radio-button label="draft">草稿</el-radio-button>
                  <el-radio-button label="approval">已审</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-button type="primary" size="mini" style="" @click="handleSearchTap">查询
              </el-button>
              <el-button size="mini" @click="handleSearchClear(search_form_name)">清空</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <div>
        <el-table size="small" v-bind="table_attrs" :ref="list_table_name" :data="page_list" :fit="true" border style="margin-top:16px;" :stripe="true" highlight-current-row height="100%">
          <el-table-column label="日期" prop="note_at" :formatter="elTimeStampFormat" width="100"></el-table-column>
          <el-table-column label="发货重量" prop="express_weight"></el-table-column>
          <el-table-column label="到货重量" prop="arrival_weight"></el-table-column>
          <el-table-column label="差异重" prop="diff_weight"></el-table-column>
          <el-table-column label="海外生产重量" prop="product_weight"></el-table-column>
          <el-table-column label="生产损耗" prop="loss_weight"></el-table-column>
          <el-table-column label="总损耗" prop="loss_weight_total"></el-table-column>
          <el-table-column label="创建人" prop="create_staff_name"></el-table-column>
          <el-table-column label="创建时间" prop="created_at" :formatter="elTimeStampFormatTime" width="136"></el-table-column>
          <el-table-column label="修改人" prop="update_staff_name"></el-table-column>
          <el-table-column label="修改时间" prop="updated_at" :formatter="elTimeStampFormatTime" width="136"></el-table-column>
          <el-table-column label="审核状态">
            <template #default="scope">
              {{AUDIT_STATUS_MAP[scope.row.status]}}
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="list-page-view">
        <el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page_option.page" :page-sizes="[5,10,15,20, 30, 50,100,200]" :page-size="page_option.size" layout="total, sizes, prev, pager, next, jumper" :total="page_option.total"></el-pagination>
      </div>

      <div>
        <div>统计范围: {{statistic.note_at_tip}}</div>
        <el-table size="small" v-bind="table_attrs" :data="[statistic]" :fit="true" border style="margin-top:16px" :stripe="true">
          <el-table-column label="总计发货重量" prop="express_weight_sum"></el-table-column>
          <el-table-column label="总计到货重量" prop="arrival_weight_sum"></el-table-column>
          <el-table-column label="总计差异重" prop="diff_weight_sum"></el-table-column>
          <el-table-column label="总计海外生产重量" prop="product_weight_sum"></el-table-column>
          <el-table-column label="总计生产损耗" prop="loss_weight_sum"></el-table-column>
          <el-table-column label="总计总损耗" prop="loss_weight_total_sum"></el-table-column>
        </el-table>
      </div>
    </div>
</template>

<script>
  import { search_col_attrs, table_attrs } from '../../config/layout'
  import {getList} from "@/api/cmp/product";
  import {formatTimestampDay} from "@/utils/time";
  import { formatTimestamp } from '../../utils/time'
  import { AUDIT_STATUS_MAP } from '../../config/enum'

  export default {
    name: 'product-list',
    data () {
      return {
        search_form_name: 'search_form_name',
        search_col_attrs,
        search_option: {note_at_range:'all',status:'all'},
        table_attrs,
        list_table_name: 'list_table_name',
        page_option: {page: 1,size: 15,total: 0},
        page_list: [],
        statistic:{},
        AUDIT_STATUS_MAP,
      }
    },
    created () {
      this.initPage()
    },
    methods: {
      initPage(){
          this.getPageList({page: 1,size:this.page_option.size},Object.assign({},this.page_option.total))
      },
      reloadPage(){
        this.getPageList(this.page_option, this.search_option)
      },
      getPageList(pageOpt,searchOpt){
        this.loading = true
        getList(pageOpt.page,pageOpt.size,searchOpt).then((resp)=>{
          this.loading = false
          this.page_list = resp.data.records
          this.page_option.page = pageOpt.page;
          this.page_option.total = resp.data.total;
          this.page_option.size = pageOpt.size;
          this.statistic = resp.data.statistic || {}
          console.log(this.page_list)
        }).catch(()=>{
          this.loading = false;
        })
      },
      handleSizeChange (pageSize) {
        this.getPageList({
          page: 1,
          size: pageSize
        }, this.search_option);
      },
      handleCurrentChange (pageIndex) {
        this.getPageList({
          page: pageIndex,
          size: this.page_option.size
        }, this.search_option)
      },
      elTimeStampFormat(row,column,val){
        return formatTimestampDay(val)
      },
      elTimeStampFormatTime(row,column,val){
        return formatTimestamp(val)
      },
      //搜索
      handleSearchTap(){
        let searchOpt = Object.assign({}, this.search_option)
        let tmp_opt = this.search_option.note_at_range
        if (tmp_opt instanceof Array){
          let range_opt_start = formatTimestampDay(tmp_opt[0])
          let range_opt_end = formatTimestampDay(tmp_opt[1])
          let tmp_range_opt = range_opt_start+"||"+range_opt_end
          searchOpt.note_at_range = tmp_range_opt
        }
        console.log('searchOpt',searchOpt)
        this.getPageList({page: 1, size: this.page_option.size}, searchOpt)
      },
      handleSearchClear(formName){
        this.$refs[formName].resetFields();
        this.reloadPage()
      }
    }
  }
</script>

<style scoped>
.list-page-view{
  margin: 16px 0;
}
/deep/.el-dialog, .el-message-box {
  width: 600px;
}
@media screen and (max-width: 1200px){
  /deep/.el-dialog, .el-message-box {
    width: 90%;
  }
  /deep/.el-pagination__jump{
    display: none !important;
  }
  /deep/ .el-row{
    display: flex;
    flex-direction: column;
  }
  /deep/.el-col-12{
    max-width: 90%;
  }
}
</style>

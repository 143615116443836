
export const AUDIT_STATUS = Object.freeze({
  Draft:1,
  Disuse:-1,
  Approval:2,
})

function init_audit_status_map(){
  let o = {}
  o[1] = '草稿'
  o[-1] = '作废'
  o[2] = '审核'
  return Object.assign({}, o)
}

export const AUDIT_STATUS_MAP = init_audit_status_map()
